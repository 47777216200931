import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { Suspense, createContext, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ReactTooltip from 'react-tooltip'
import { getAuth } from 'firebase/auth'
import { Loader } from 'components/Loader'
import Toaster from 'components/Toaster'
import Button from 'components/Button'
import { commonMessages } from 'utils/messages'
import { EVENT_NAME, LOCAL_STORAGE } from 'const/common'
import { ReactComponent as ErrorFound } from 'assets/images/PageNotWorking.svg'
import axios from 'axios'
import {
	handleStorageChange,
	onBeforeUnload,
	windowAddEventListener,
	windowRemoveEventListener,
} from 'utils/appCommon'
import { getLocalStorageDetails, onStringify, setLocalStorageDetails, signOut } from 'utils/common'
import {
	getLoggedInUserClientDetail,
	globalRouter,
	useMessageStoreData,
	useTaskStoreData,
	useTimeStoreData,
} from 'hooks/common'
import {
	onGetDynamicRoutes,
	onInsertChildren,
	onInsertChildrenInLayout,
} from 'router/router.copy.config'
import { usePermissionDetails } from 'modules/Dashboard copy/Services.hooks'
import { useAuthContext } from 'hooks/auth'
import { getPermission } from 'modules/Dashboard copy/Services.service'
import { onGetScreenSlug } from 'hooks/customHooks'
import { SCREEN_NAME } from 'const/screen'
import usePageLogger from 'components/PageLogger'
import { ErrorFallback } from 'hooks/commonErrorBoundary'
import { connectSocket } from 'components/SocketConectivity/socketConnection'
import { errorNotify, successNotify } from 'utils/toster'

export const TimerContext = createContext<any>({})

function App() {
	const [isNetworkError, setIsNetworkError] = useState(false)
	const token = getLocalStorageDetails(LOCAL_STORAGE?.TOKEN)
	const navigate = useNavigate()
	const location = useLocation()
	const myProfileScreenPath = onGetScreenSlug(SCREEN_NAME?.MEMBER_PROFILE)
	const pathName = location?.pathname.includes(myProfileScreenPath)
	const [showMessagePopUp, setShowMessagePopUp] = useState(true)
	const [hasConnectedOnce, setHasConnectedOnce] = useState(false)
	const [routesDetails, setroutesDetails] = useState<any>([])
	const [selectedServiceRouteData, setSelectedServiceRouteData] = useState(onGetDynamicRoutes())
	const { context } = useAuthContext()
	const timerContextValue = useTimeStoreData()
	const messageContextValue = useMessageStoreData()
	const taskContextValue = useTaskStoreData()
	usePageLogger()

	/**
	 * *Custom hooks Section
	 */
	const alreadyMount = useRef(false)
	useEffect(() => {
		alreadyMount.current = true
	}, [])

	/**
	 * Handles updates to permissions by fetching and processing new route data from storage.
	 * Updates the 'routesDetails' state with the processed route data.
	 */
	const serviceName: any = getLocalStorageDetails(LOCAL_STORAGE?.SERVICE_NAME)
	const isPermissionExist: any = getLocalStorageDetails(LOCAL_STORAGE?.PERMISSION_DETAIL)

	const userDetail = getLoggedInUserClientDetail()

	const permissionDetail: any = usePermissionDetails({
		client: userDetail?.clientDetails?.clientId,
		providerRole: userDetail?.providerRoleDetails?.providerRole ?? undefined,
		isEnabled:
			true &&
			!!context?.user &&
			!!serviceName &&
			alreadyMount?.current &&
			!!isPermissionExist &&
			false,
	})

	useEffect(() => {
		if (!!context?.user && userDetail?.clientDetails?.clientId) {
			onGetPermission()
		}
	}, [!!context?.user])

	const onGetPermission = async () => {
		const CancelToken = axios.CancelToken
		const source = CancelToken.source()
		const details = await getPermission(
			{
				client: userDetail?.clientDetails?.clientId,
				providerRole: userDetail?.providerRoleDetails?.providerRole ?? undefined,
			},
			source,
		)
		onUpdateStorageAndRoutes(details?.data)
	}
	const onUpdateStorageAndRoutes = (permissionDetail: any) => {
		const permissionStringify: string = onStringify(permissionDetail)
		setLocalStorageDetails(LOCAL_STORAGE?.PERMISSION_DETAIL, permissionStringify)
		onPermissionUpdate()
	}

	useEffect(() => {
		if (permissionDetail?.data?.data) {
			onUpdateStorageAndRoutes(permissionDetail?.data?.data)
		}
	}, [permissionDetail?.data?.data])

	const onUpdateRoute = (event: any) => {
		const passedArray = event?.detail
		const insertChildrenInLayout = onInsertChildrenInLayout(passedArray)
		setSelectedServiceRouteData(insertChildrenInLayout)
	}

	/**
	 * Handles actions to be taken when a network success occurs.
	 * Notifies the user that they are online now.
	 */
	const onNetworkSuccess = () => {
		setIsNetworkError(false)
		successNotify(commonMessages?.backToOnline)
	}

	/**
	 * Handles actions to be taken when a network error occurs.
	 * Notifies the user to check their internet connection.
	 */
	const onNetworkError = () => {
		setIsNetworkError(true)
		return errorNotify(commonMessages?.checkInternetConnection)
	}

	const onPermissionUpdate = () => {
		const routeDetails = handleStorageChange()
		setroutesDetails(routeDetails)
	}

	const auth: any = getAuth()
	// Call socket io function to connectivity
	useEffect(() => {
		const userId = auth?.currentUser?.uid
		if (userId && !hasConnectedOnce) {
			connectSocket(userId, messageContextValue)
			setHasConnectedOnce(true)
		}
	}, [auth?.currentUser?.uid, hasConnectedOnce])

	/**
	 * useEffect hook that sets up event listeners for network and permission updates.
	 * Triggers appropriate functions when events occur and removes the listeners on cleanup.
	 */

	useEffect(() => {
		windowAddEventListener(EVENT_NAME?.BEFORE_UNLOAD, onBeforeUnload)
		windowAddEventListener(EVENT_NAME?.OFFLINE, onNetworkError)
		windowAddEventListener(EVENT_NAME?.ONLINE, onNetworkSuccess)
		windowAddEventListener(EVENT_NAME?.UPDATE_ROUTES, onUpdateRoute)
		return () => {
			windowRemoveEventListener(EVENT_NAME?.ONLINE, onNetworkSuccess)
			windowRemoveEventListener(EVENT_NAME?.OFFLINE, onNetworkError)
			windowRemoveEventListener(EVENT_NAME?.UPDATE_ROUTES, onUpdateRoute)
			windowRemoveEventListener(EVENT_NAME?.BEFORE_UNLOAD, onBeforeUnload)
		}
	}, []) // Empty dependency array ensures this runs once when component  mounts

	/**
	 * Updates route data based on the existing 'routesDetails' array.
	 * If 'routesDetails' is not empty, processes it using 'onInsertChildren'
	 * and sets the result as the new 'routeData' state.
	 */
	const updateRouteData = () => {
		if (routesDetails && routesDetails?.length > 0) {
			const createdRoutes: any = onInsertChildren(routesDetails)
			const insertChildrenInLayout = onInsertChildrenInLayout(createdRoutes)
			setSelectedServiceRouteData(insertChildrenInLayout)
		}
	}

	/**
	 * useEffect hook that triggers the 'updateRouteData' function whenever the 'routesDetails'
	 * dependency changes.
	 * This effect is used for reacting to changes in the 'routesDetails' state.
	 */
	useEffect(() => {
		updateRouteData()
	}, [routesDetails])

	/**
	 * useEffect hook that checks for permission details in local storage.
	 * If permission details exist, it triggers 'onPermissionUpdate' to handle updates.
	 * If no permission details are found, it navigates to the sign-in page.
	 */

	useEffect(() => {
		const isPermissionExist: any = getLocalStorageDetails(LOCAL_STORAGE?.PERMISSION_DETAIL)

		if (isPermissionExist) {
			onPermissionUpdate()
		} else if (!token) {
			signOut()
		}
	}, [])

	/**
	 * Array of custom route objects to be used in the application.
	 * Each object represents a route with its corresponding layout and children.
	 */

	globalRouter.navigate = navigate
	//  elements created
	const elements = useRoutes(selectedServiceRouteData)
	const userOffline = !navigator.onLine

	// Function to handle reload event
	const handleReload = () => {
		window.location.reload()
	}

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setShowMessagePopUp(false)
		}, 5000)

		// Clear the timeout to prevent it from triggering after the component is unmounted
		return () => clearTimeout(timeoutId)
	}, [])

	useEffect(() => {
		ReactTooltip.rebuild()
	}, [])

	useEffect(() => {
		if (!pathName) {
			taskContextValue?.setTaskType(null)
			taskContextValue?.setTask(null)
		}
	}, [pathName])

	return (
		<>
			{!userOffline ? (
				<ErrorBoundary
					FallbackComponent={(props) => {
						return ErrorFallback({
							isNetworkError,
							...props,
						})
					}}
				>
					<TimerContext.Provider
						value={{ timerContextValue, taskContextValue, messageContextValue }}
					>
						<Suspense fallback={<Loader />}>{elements}</Suspense>
						<Toaster />
					</TimerContext.Provider>
				</ErrorBoundary>
			) : (
				<div role="alert" className="flex flex-col items-center justify-center h-full flex-1 gap-4">
					<p>{`${
						userOffline ? 'No internet connection' : commonMessages.errorHandlingMessage
					}:`}</p>
					<pre>
						{' '}
						<ErrorFound />{' '}
					</pre>
					<Button onClick={handleReload}>Try again</Button>
				</div>
			)}
		</>
	)
}

export default App
