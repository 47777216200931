import { useActivityLogger } from 'components/ActivityLogger'
import Button from 'components/Button'
import { commonMessages } from 'utils/messages'
import { ReactComponent as ErrorFound } from 'assets/images/PageNotWorking.svg'
import { useEffect } from 'react'

export function ErrorFallback({
	error,
	resetErrorBoundary,
	isNetworkError,
}: {
	error: Error
	resetErrorBoundary: (...args: Array<unknown>) => void
	isNetworkError: boolean
}) {
	const handleRetry = () => {
		window.location.reload()
	}
	useEffect(() => {
		const isChunkLoadError =
			error?.message?.includes('Loading chunk') || error?.message?.includes('ChunkLoadError')
		if (isChunkLoadError && !isNetworkError) {
			handleRetry()
		}
	}, [])

	useActivityLogger(error as Error)
	return (
		<div role="alert" className="flex flex-col items-center justify-center h-full flex-1 gap-4">
			<p>{`${commonMessages.errorHandlingMessage}:`}</p>
			<pre>
				{' '}
				<ErrorFound />{' '}
			</pre>
			<Button onClick={resetErrorBoundary}>Try again</Button>
		</div>
	)
}
